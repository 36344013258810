import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "bootstrap/dist/css/bootstrap.min.css"
import "../style.scss"
import { Col, Container, Row } from "react-bootstrap"
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { SoortenBeugelsNavigation } from "../components/SoortenBeugelsNavigation"
import LandingChair from "../assets/svgs/landing-chair.svg"
import LandingBlob from "../assets/svgs/Landing.svg"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <Container id="landing">
      <LandingBlob id="responsive-landing-blob" />
      <Row>
        <Col md={5} className="my-auto">
          <Row className={"mx-auto"}>
            <h1 id={"catch-phrase"}>
              Een betoverende glimlach is het mooiste juweel dat je kan dragen
            </h1>
            <Link to={"/contact"} className={"btn ripple"}>
              Contacteer ons
            </Link>
          </Row>
        </Col>
        <Col md id="chair-landing" className="my-auto">
          <LandingChair />
        </Col>
      </Row>
      {/* <div>
      <Row>
          <h2 className="landing-section-title mx-auto pb-5">
            Mededeling
          </h2>
        </Row>
        <Row className="text-justify">
          <p>
            Beste patiënt,
            <br />
            <br />
tekst<br />
            <br />
            Uw Orthodontisch Team
          </p>
        </Row>
      </div> */}
      <div className="emergency">
        <Row>
          <h2 className="landing-section-title mx-auto pt-5">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              style={{ height: "1em" }}
            />
            {" Dringende wachtdienst Orthodontie"}
          </h2>
        </Row>
        <Row className="flex-row justify-content-center pt-5 text-justify">
          <p>
            Tijdens het weekend en op feestdagen wordt er een wachtdienst
            georganiseerd per provincie.
            <br />
            Je kan er in de voormiddag van 10:00 tot 12:00 uur terecht via het
            volgende nummer:
            <a id="phone-behind-landing-fix" href="tel:+32(0)70222088">
              {"070 22 20 88"}
            </a>
          </p>
        </Row>
      </div>

      <Row>
        <h2 className="landing-section-title mx-auto pb-3">
          Verloop van behandeling
        </h2>
      </Row>
      <Row id="verloop-behandeling" className="justify-content-center">
        <ul>
          <li>
            <Link to={"/verloop-behandeling#eerste-consultatie"}>
              Eerste consultatie - diagnose
            </Link>
          </li>
          <li>
            {" "}
            <Link to={"/verloop-behandeling#planbespreking"}>
              Tweede consultatie - planbespreking
            </Link>
          </li>
          <li>
            <Link to={"/verloop-behandeling#behandeling"}>
              Actieve behandeling
            </Link>
          </li>
          <li>
            <Link to={"/verloop-behandeling#retentie"}>Retentiefase</Link>
          </li>
        </ul>
      </Row>
      <Row>
        <h2 className="landing-section-title mx-auto pb-5">Soorten beugels</h2>
      </Row>
      <Row>
        <SoortenBeugelsNavigation />
      </Row>
    </Container>
    <div className="background-color">
      <h2>Om een afspraak te maken</h2>
      <Link to={"/contact"} className={"secondary-btn ripple"}>
        Contacteer ons
      </Link>
    </div>
  </Layout>
)

export default IndexPage
